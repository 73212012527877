import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import UserRank from '@aurora/shared-client/components/users/UserRank/UserRank';
import type {
  EventComparison,
  FilterEvent,
  RevisionsDiff
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useCallback } from 'react';
import { Badge, useClassNameMapper } from 'react-bootstrap';
import {
  handleSpoilerClick,
  isSpoilerElement
} from '@aurora/shared-client/helpers/editor/EditorSpoilerHelper';
import UserLink from '../users/UserLink/UserLink';
import useTranslation from '../useTranslation';
import localStyles from './ContentComparison.module.pcss';

interface Props {
  /**
   * Whether tags should be shown in comparison.
   */
  useTags?: Boolean;
  /**
   * Final data after comparison.
   */
  diffResult: RevisionsDiff | EventComparison;
  /**
   * Content filter events
   */
  filterEvent?: FilterEvent;
  /**
   * Whether user details should be shown
   */
  showUserDetails?: Boolean;
}

/**
 *  Renders the comparison between content (messages/filterEvents)
 *
 * @constructor
 *
 * @author Aditi Agarwal
 */
const ContentComparison: React.FC<React.PropsWithChildren<Props>> = ({
  useTags = false,
  filterEvent,
  diffResult,
  showUserDetails = false
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.CONTENT_COMPARISON
  );
  const { user, timestamp } = filterEvent || {};
  const { formatRelativeTime } = useDateTime();
  const { subject, introduction, teaser, body } = diffResult || {};
  const { tags } = (diffResult as EventComparison) || {};

  /**
   * Handles the click event on the body of the message
   *
   * @param event Mouse Event
   */
  const handleBodyClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const target = event.target as HTMLElement;

    if (isSpoilerElement(target)) {
      handleSpoilerClick(event, target);
    }
  }, []);

  if (textLoading) {
    return null;
  }

  /**
   * Renders the comparison between two given revisions.
   */
  function renderCompareContent(): React.ReactElement {
    return (
      <div
        className={cx('lia-compare-container-body wrap-title', {
          'lia-content-filters': filterEvent
        })}
      >
        {subject && (
          <h2 className={cx('lia-g-mb-20')} dangerouslySetInnerHTML={{ __html: subject }} />
        )}
        {introduction && (
          <h4
            className={cx('lia-g-mb-20 font-weight-normal')}
            dangerouslySetInnerHTML={{ __html: introduction }}
          />
        )}
        {body && (
          <div
            className={cx('lia-g-message-body d-flex flex-column')}
            aria-hidden="true"
            onClick={handleBodyClick}
            dangerouslySetInnerHTML={{ __html: body }}
          />
        )}
        {useTags &&
          tags?.length > 0 &&
          tags?.map((tag, index) => (
            <Badge
              tabIndex={-1}
              key={index}
              variant="light"
              className={cx('lia-filter-tag')}
              data-testid="ContentComparison.TagName"
              dangerouslySetInnerHTML={{ __html: tag }}
            />
          ))}
        {teaser && (
          <div className={cx('lia-compare-summary')}>
            <div className={cx('lia-compare-summary-title')}>{formatMessage('summary')}</div>
            <div dangerouslySetInnerHTML={{ __html: teaser }} />
          </div>
        )}
      </div>
    );
  }

  /**
   * Renders the user details for the filter.
   */
  function renderUserDetails(): React.ReactElement {
    return (
      <div className={cx('d-flex lia-g-mb-20')} data-testid="ContentComparison.UserDetails">
        <div className={cx('lia-g-mr-10')}>
          <UserLink user={user} className={cx('d-block')} useHoverCard={false}>
            <UserAvatar user={user} size={IconSize.PX_50} />
          </UserLink>
        </div>
        <div className={cx('d-flex flex-column justify-content-center')}>
          <div className={cx('d-flex align-items-center')}>
            <UserLink
              className={cx('lia-g-divider lia-author-login')}
              user={user}
              useHoverCard={false}
            >
              {user?.login}
            </UserLink>
            <UserRank
              className={cx('lia-g-divider lia-author-rank d-inline-flex align-items-center')}
              user={user}
            />
          </div>
          <span className={cx('lia-filter-timestamp')}>{formatRelativeTime(timestamp)}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={cx('lia-container lia-g-pl-0 lia-g-pr-0')} data-testid="ContentComparison">
      {showUserDetails && renderUserDetails()}
      {renderCompareContent()}
    </div>
  );
};

export default ContentComparison;
